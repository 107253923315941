import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import {catchError} from 'rxjs/operators'; 


@Injectable()

export class CommonServices {

  public errorMsg: string;

  constructor(public httpClient: HttpClient) { }

  
    // send lead email
    sendLeadEmail(systemUserProfile: any ) {
      return this.httpClient.post( 'http://50.57.9.73:5000/send-email', systemUserProfile.value)
      .pipe(
      catchError( this.handleError) )
    }

    // send quoatation email
    sendQuotation(systemUserProfile: any ) {
      return this.httpClient.post( 'http://50.57.9.73:5000/send-quotation', systemUserProfile.value)
      .pipe(
      catchError( this.handleError) )
    }




  /**
  * Handle Errors
  */
  handleError(err) {

    let errMessage: string;

    if (err instanceof Response) {
       // tslint:disable-next-line:prefer-const
       let body = err.json() || '';
       // tslint:disable-next-line:prefer-const
      //  let error = body.error || JSON.stringify(body);
      var error = 'no se '
       errMessage = `${err.status} - ${err.statusText} || '' ${error}`;
  
    } else {
      errMessage = err.messsage ? err.message : err.toString();
    }
  
    return throwError(errMessage);
  }
}
